import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  NewUserEmailVerified,
  AuthError,
  Unsubscribe,
  Dialog,
  LoadingSpinner,
  AuthInitiatedFromAnotherDeviceOrBrowser
} from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { FirebaseContext, LocalContext } from 'context';
import { navigate } from 'gatsby';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { clearQueryParams, formatName } from 'utils';
import { defaultColors } from 'styles';
import { signInWithEmailLink } from 'firebase/auth';

function Auth() {
  const [showNewUserEmailVerifiedDialog, setShowNewUserEmailVerifiedDialog] = useState(false);
  const [authError, setAuthError] = useState({ code: null, message: null });
  const [showAuthErrorDialog, setShowAuthErrorDialog] = useState(false);
  const [showUnsubscribeDialog, setShowUnsubscribeDialog] = useState(false);
  const { loading, firebase, user } = useContext(FirebaseContext);
  const { theme, siteName, handleDialog, authInitiatedFromAnotherDeviceOrBrowserDialogRef } =
    useContext(LocalContext);
  const [
    showAuthInitiatedFromAnotherDeviceOrBrowserDialog,
    setShowAuthInitiatedFromAnotherDeviceOrBrowserDialog
  ] = useState(false);
  const [parsedShortUrlData, setParsedShortUrlData] = useState(null);
  const userDetailsFromMagicLink = parsedShortUrlData?.userDetailsFromMagicLink;
  const action = userDetailsFromMagicLink?.action;
  const continueUrl = parsedShortUrlData?.continueUrl;
  const unsubscribe = parsedShortUrlData?.unsubscribe;
  const magicLink = parsedShortUrlData?.magicLink;
  const mode = parsedShortUrlData?.mode;
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
  const newUserEmailVerifiedDialogRef = useRef(null);
  const authErrorDialogRef = useRef(null);
  const unsubscribeDialogRef = useRef(null);

  useEffect(() => {
    if (user && action === 'login' && continueUrl) {
      const { pathname } = new URL(continueUrl);
      if (showAuthInitiatedFromAnotherDeviceOrBrowserDialog) {
        handleDialog({
          dialogRef: authInitiatedFromAnotherDeviceOrBrowserDialogRef,
          animation: 'close',
          stateHandler: () => setShowAuthInitiatedFromAnotherDeviceOrBrowserDialog(false),
          callback: () => {
            setShowLoadingSpinner(true);
            navigate(pathname);
          }
        });
      } else {
        navigate(pathname);
      }
    }
  }, [user, action, continueUrl, showAuthInitiatedFromAnotherDeviceOrBrowserDialog]);

  useEffect(() => {
    if (unsubscribe) {
      setShowUnsubscribeDialog(true);
    }
  }, [unsubscribe]);

  const authenticateUser = async ({
    newUserEmailFromLocalStorage,
    existingUserEmailFromLocalStorage,
    emailOfUserWhoInitiatedAuthFromAnotherDeviceOrBrowser
  }) => {
    try {
      // signInWithEmailLink() either signs a user into Firebase Auth if they already have an
      // account, or creates a new Firebase Auth account for them if they're a new user.
      const result = await signInWithEmailLink(
        firebase.auth,
        newUserEmailFromLocalStorage ||
          existingUserEmailFromLocalStorage ||
          emailOfUserWhoInitiatedAuthFromAnotherDeviceOrBrowser,
        magicLink
      );

      const { isNewUser } = result._tokenResponse;

      if (isNewUser) {
        const formattedName = formatName(userDetailsFromMagicLink.name);
        const referringUrl = JSON.parse(
          window.localStorage.getItem(`referringUrl${siteName}`)
        )?.data;

        // Creates account in Firestore database
        await firebase.registration.createAccount({
          name: formattedName,
          email:
            newUserEmailFromLocalStorage || emailOfUserWhoInitiatedAuthFromAnotherDeviceOrBrowser,
          uid: result.user.uid,
          referringUrl,
          company: userDetailsFromMagicLink.company,
          companyAddress: userDetailsFromMagicLink.companyAddress,
          numberOfEmployees: userDetailsFromMagicLink.numberOfEmployees,
          phoneNumber: userDetailsFromMagicLink.phoneNumber,
          businessType: userDetailsFromMagicLink.businessType,
          eid: '2'
        });

        await firebase.auth.currentUser.reload();

        if (emailOfUserWhoInitiatedAuthFromAnotherDeviceOrBrowser) {
          handleDialog({
            dialogRef: authInitiatedFromAnotherDeviceOrBrowserDialogRef,
            animation: 'close',
            stateHandler: () => setShowAuthInitiatedFromAnotherDeviceOrBrowserDialog(false),
            callback: () => {
              setShowLoadingSpinner(false);
              setShowNewUserEmailVerifiedDialog(true);
            }
          });
        } else {
          setShowLoadingSpinner(false);
          setShowNewUserEmailVerifiedDialog(true);
        }

        if (newUserEmailFromLocalStorage) {
          window.localStorage.removeItem(`newUserEmail-${siteName}`);
        }
        if (referringUrl) {
          window.localStorage.removeItem(`referringUrl${siteName}`);
        }
        // TODO: Send Welcome To Panacea Email.
      } else if (!isNewUser) {
        if (newUserEmailFromLocalStorage) {
          window.localStorage.removeItem(`newUserEmail-${siteName}`);
          window.localStorage.removeItem(`referringUrl${siteName}`);
        }
        if (existingUserEmailFromLocalStorage) {
          window.localStorage.removeItem(`existingUserEmail-${siteName}`);
          setShowLoadingSpinner(true);
        }
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      setShowLoadingSpinner(false);

      if (existingUserEmailFromLocalStorage) {
        window.localStorage.removeItem(`existingUserEmail-${siteName}`);
      }

      if (newUserEmailFromLocalStorage) {
        window.localStorage.removeItem(`newUserEmail-${siteName}`);
        window.localStorage.removeItem(`referringUrl${siteName}`);
      }

      const userFromAuth = firebase?.auth?.currentUser;

      if (userFromAuth) {
        await userFromAuth.delete();
        Sentry.captureMessage(`Problem registering user: ${userDetailsFromMagicLink}`);
      }

      switch (error.code) {
        case 'auth/invalid-email':
          setAuthError({
            code: error.code,
            message: ''
          });
          break;
        case 'auth/invalid-action-code':
          setAuthError({
            code: error.code,
            message: `Invalid or expired link.<br />Please try ${
              action === 'registration' ? 'registering' : 'logging in'
            } again.<br /><br />`
          });
          setShowAuthErrorDialog(true);
          break;
        default:
          setAuthError({
            code: error.code,
            message: error.message
          });
          setShowAuthErrorDialog(true);
          break;
      }
    }
  };

  useEffect(() => {
    if (mode) {
      const newUserEmailFromLocalStorage = JSON.parse(
        window.localStorage.getItem(`newUserEmail-${siteName}`)
      );
      const existingUserEmailFromLocalStorage = JSON.parse(
        window.localStorage.getItem(`existingUserEmail-${siteName}`)
      );
      switch (mode) {
        case 'signIn':
          if (newUserEmailFromLocalStorage || existingUserEmailFromLocalStorage) {
            authenticateUser({
              newUserEmailFromLocalStorage,
              existingUserEmailFromLocalStorage
            });
          } else if (!firebase?.auth?.currentUser && userDetailsFromMagicLink) {
            setShowLoadingSpinner(false);
            setShowAuthInitiatedFromAnotherDeviceOrBrowserDialog(true);
          } else if (firebase?.auth?.currentUser) {
            // Hmmm. Should we be doing something here? Test for edge cases. Maybe something to do
            // with expired links?
          }
          break;
        default:
          break;
      }
    }
  }, [mode]);

  useEffect(() => {
    if (!loading && firebase) {
      const shortUrlId = window.location.pathname.split('/')[2];
      firebase.emails
        .parseShortUrl(shortUrlId)
        .then(({ data }) => setParsedShortUrlData(data))
        .catch((error) => {
          console.error(error);
          switch (error.code) {
            case 'functions/not-found':
              setAuthError({
                code: error.code,
                message: error.message
              });
              setShowAuthErrorDialog(true);
              window.localStorage.removeItem(`newUserEmail-${siteName}`);
              break;
            default:
              setAuthError({
                code: error.code,
                message: error.message
              });
              setShowAuthErrorDialog(true);
              break;
          }
        });
    }
  }, [loading, firebase]);

  const closeUnsubscribeDialog = () =>
    handleDialog({
      dialogRef: unsubscribeDialogRef,
      animation: 'close',
      stateHandler: () => setShowUnsubscribeDialog(false),
      callback: () => {
        setShowLoadingSpinner(true);
        clearQueryParams();
        navigate('/');
      }
    });

  const closeNewUserEmailVerifiedDialog = () =>
    handleDialog({
      dialogRef: newUserEmailVerifiedDialogRef,
      animation: 'close',
      stateHandler: () => setShowNewUserEmailVerifiedDialog(false),
      callback: () => {
        setShowLoadingSpinner(true);
        // const { pathname } = new URL(continueUrl);
        // console.log('pathname');
        // navigate(pathname);
        navigate(`/events/elevate-your-digital-sales-expand-your-online-reach/livestream`);
      }
    });

  const closeAuthErrorDialog = () =>
    handleDialog({
      dialogRef: authErrorDialogRef,
      animation: 'close',
      stateHandler: () => setShowAuthErrorDialog(false),
      callback: async () => {
        setShowLoadingSpinner(true);
        setAuthError({ code: null, message: null });
        navigate('/');
      }
    });

  return (
    <Container>
      <Dialog
        ref={unsubscribeDialogRef}
        isVisible={showUnsubscribeDialog}
        onDismiss={closeUnsubscribeDialog}
        contentStyle={{
          maxWidth: '31.25rem'
        }}>
        <Unsubscribe theme={theme} closeUnsubscribeDialog={closeUnsubscribeDialog} />
      </Dialog>
      <Dialog
        ref={newUserEmailVerifiedDialogRef}
        isVisible={user?.name && showNewUserEmailVerifiedDialog}
        contentStyle={{
          maxWidth: '27.5rem'
        }}>
        <NewUserEmailVerified
          theme={theme}
          user={user}
          closeNewUserEmailVerifiedDialog={closeNewUserEmailVerifiedDialog}
        />
      </Dialog>
      <Dialog
        ref={authInitiatedFromAnotherDeviceOrBrowserDialogRef}
        isVisible={showAuthInitiatedFromAnotherDeviceOrBrowserDialog}>
        <AuthInitiatedFromAnotherDeviceOrBrowser
          action={action}
          authenticateUser={authenticateUser}
          authError={authError}
          setAuthError={setAuthError}
          theme={theme}
          setShowAuthInitiatedFromAnotherDeviceOrBrowserDialog={
            setShowAuthInitiatedFromAnotherDeviceOrBrowserDialog
          }
        />
      </Dialog>
      <Dialog
        ref={authErrorDialogRef}
        isVisible={showAuthErrorDialog && authError}
        onDismiss={closeAuthErrorDialog}
        contentStyle={{
          maxWidth: '31.25rem'
        }}>
        <AuthError
          theme={theme}
          authError={authError}
          closeAuthErrorDialog={closeAuthErrorDialog}
        />
      </Dialog>
      <AnimatePresence>
        {showLoadingSpinner && (
          <LoadingSpinner
            initial={false}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              width: '8rem',
              color: defaultColors.primary
            }}
          />
        )}
      </AnimatePresence>
    </Container>
  );
}

const Container = styled(motion.div)`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100vw;
`;

export default Auth;
